import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import 'swiper/css';
import './index.css';

SwiperCore.use([Navigation, Autoplay]);

export default function CarouselV2(props) {
  const swiperRef = React.useRef(null);

  const isMobile = props.isMobile;
  const settings = {
    /* slidesPerColumn: isMobile ? 3 : 2,
		slidesPerColumnFill: 'row', */
    slidesPerView: isMobile ? 2 : 5,
    centeredSlides: false,
    waitForTransition: false,
    freeMode: true,
    loop: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    speed: 5000,
    allowTouchMove: false,
  };
  return (
    <Swiper {...settings} ref={swiperRef}>
      {props.data.map((val, idx) => {
        return (
          <SwiperSlide
            key={`hiringSlide-${idx}`}
            style={{ height: '172px', width: '270px' }}
          >
            <Card nestedData={val} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

function Card(props) {
  const companyLogo = getImage(props.nestedData.companyLogo.localFile);
  const profileImage = getImage(props.nestedData.profileImage.localFile);

  return (
    <div className="mx-2 flex h-full  flex-col items-center justify-center bg-crio-neutral-100 p-2">
      <div style={{ maxHeight: '100%', height: '50px', width: '50px' }}>
        {/* <Image
            style={{ maxHeight: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
            filename={`${props.nestedData.profileImage.split('/')[4]}`}
          ></Image> */}
        <GatsbyImage
          loading="lazy"
          image={profileImage}
          alt={'profile'}
          style={{ maxHeight: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>

      <h5 className="text-center text-xs text-crio-neutral-600">
        {props.nestedData.name}
      </h5>
      <h5 className="text-center text-xs font-light text-crio-neutral-600">
        is in
      </h5>
      <div style={{ maxHeight: '100%', height: '50px', width: '120px' }}>
        <GatsbyImage
          loading="lazy"
          image={companyLogo}
          alt={'company'}
          style={{ maxHeight: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
    </div>
  );
}
